<template>
  <CRow>

    <CCol :md="3">
      <CWidgetStatsA class="mb-4" color="primary">
      <template #title>Numero segnali filtri Px/Fx/Cx</template>
        <template #value>
          {{numSegnaliPFC}}
        </template>
      </CWidgetStatsA>
    </CCol>
    <CCol :md="3">
      <CWidgetStatsA class="mb-4" color="danger">
        <template #value>
        {{resultsAll.pareggi}}
        </template>
        <template #title>Pareggi</template>
      </CWidgetStatsA>
      <CWidgetStatsA class="mb-4" color="success">
        <template #value>
        {{resultsAll.spareggi}}
        </template>
        <template #title>Spareggi</template>
      </CWidgetStatsA>
    </CCol>
    <CCol :md="3">
      <CWidgetStatsA class="mb-4" color="danger">
        <template #value>
        {{resultsAll.pareggi_ht}}
        </template>
        <template #title>Pareggi HT</template>
      </CWidgetStatsA>
      <CWidgetStatsA class="mb-4" color="success">
        <template #value>
        {{resultsAll.spareggi_ht}}
        </template>
        <template #title>Spareggi HT</template>
      </CWidgetStatsA>
    </CCol>
    <CCol>
      <CWidgetStatsA class="mb-4" color="danger">
        <template #value>
        {{resultsAll.under25}}
        </template>
        <template #title>Under 2.5 FT</template>
      </CWidgetStatsA>
      <CWidgetStatsA class="mb-4" color="success">
        <template #value>
        {{resultsAll.over25}}
        </template>
        <template #title>Over 2.5 FT</template>
      </CWidgetStatsA>
    </CCol>

  <CRow>
      <CCol :md="12">
        <CCard class="mb-4">
          <CCardHeader> Situazione filtri </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol :sm="12" :lg="4">
                <CRow>
                  <CCol :sm="6">
                    <div
                      class="
                        border-start border-start-4 border-start-info
                        py-1
                        px-3
                        mb-3
                      "
                    >
                      <div class="text-medium-emphasis small">Filtro PX</div>
                      <div class="fs-5 fw-semibold">{{resultsAll.numPX}}</div>
                    </div>
                  </CCol>
                  <CCol :sm="4">
                    <div
                      class="
                        border-start border-start-4 border-start-danger
                        py-1
                        px-3
                        mb-3
                      "
                    >
                      <div class="text-medium-emphasis small">
                        Filtro CX
                      </div>
                      <div class="fs-5 fw-semibold">{{resultsAll.numCX}}</div>
                    </div>
                  </CCol>
                </CRow>
                <hr class="mt-0" />
                <CRow>
                  <CCol :sm="4">
                    <div
                      class="
                        border-start border-start-4 border-start-warning
                        py-1
                        px-3
                        mb-3
                      "
                    >
                      <div class="text-medium-emphasis small">Filtro FX</div>
                      <div class="fs-5 fw-semibold">{{resultsAll.numFX}}</div>
                    </div>
                  </CCol>
                </CRow>
                <hr class="mt-0" />
                <div
                  v-for="item in FiltersResume"
                  :key="item.title"
                  class="progress-group mb-4"
                >
                  <div class="progress-group-prepend">
                    <span class="text-medium-emphasis small">{{
                      item.title
                    }}</span>
                  </div>
                  <div class="progress-group-bars">
                    <span class="title">Win: {{item.value3}}</span>
                    <span class="ms-auto fw-semibold" style="float:right;">{{ Math.round((item.value1) * 100) / 100 }} %</span>
                    <CProgress thin color="info" :value="item.value1" />

                    <span class="title">Lose: {{item.value4}}</span>
                    <span class="ms-auto fw-semibold" style="float:right;">{{ Math.round((item.value2) * 100) / 100 }}%</span>
                    <CProgress thin color="danger" :value="item.value2" />
                  </div>
                </div>
              </CCol>
              <CCol :sm="12" :lg="8">
                <CRow>
                  <CCol :sm="4">
                    <div
                      class="
                        border-start border-start-4 border-start-success
                        py-1
                        px-3
                        mb-3
                      "
                    >
                      <div class="text-medium-emphasis small">Filtro AVG</div>
                      <div class="fs-5 fw-semibold">{{resultsAll.numAVG}}</div>
                    </div>
                  </CCol>

                </CRow>
                <hr class="mt-0" />
                <CRow>
                  <CCol :sm="4">
                    <div
                      class="
                        border-start border-start-4 border-start-success
                        py-1
                        px-3
                        mb-3
                      "
                    >
                      <div class="text-medium-emphasis small">Filtro U3</div>
                      <div class="fs-5 fw-semibold">{{resultsAll.numU3}}</div>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CRow>
</template>

<script>
import { getData } from '../../plugins/fetch'
import { ref } from 'vue-demi'
export default {
  name: 'WidgetsStatsA',
  setup() {
    const FiltersResume = ref([]);

    const numSegnaliPFC = ref(0);
    const resultsAll = ref({});
    getData("https://api.soccerstandings.online/getAllRis").then((results) => {
      resultsAll.value = results;
      numSegnaliPFC.value = results.partite_totali;
      FiltersResume.value = [
        { title: 'Filtro Px', value1: (results.filtersResume.filtro_px[0] / (results.filtersResume.filtro_px[0] + results.filtersResume.filtro_px[1])) * 100, value2: (results.filtersResume.filtro_px[1] / (results.filtersResume.filtro_px[0] + results.filtersResume.filtro_px[1])) * 100, value3 : results.filtersResume.filtro_px[0], value4 : results.filtersResume.filtro_px[1]},
        { title: 'Filtro Cx', value1: (results.filtersResume.filtro_cx[0] / (results.filtersResume.filtro_cx[0] + results.filtersResume.filtro_cx[1])) * 100, value2: (results.filtersResume.filtro_cx[1] / (results.filtersResume.filtro_cx[0] + results.filtersResume.filtro_cx[1])) * 100, value3 : results.filtersResume.filtro_cx[0], value4 : results.filtersResume.filtro_cx[1]},
        { title: 'Filtro Fx', value1: (results.filtersResume.filtro_fx[0] / (results.filtersResume.filtro_fx[0] + results.filtersResume.filtro_fx[1])) * 100, value2: (results.filtersResume.filtro_fx[1] / (results.filtersResume.filtro_fx[0] + results.filtersResume.filtro_fx[1])) * 100, value3 : results.filtersResume.filtro_fx[0], value4 : results.filtersResume.filtro_fx[1]},
        { title: 'Filtro AVG', value1: (results.filtersResume.filtro_avg[0] / (results.filtersResume.filtro_avg[0] + results.filtersResume.filtro_avg[1])) * 100, value2: (results.filtersResume.filtro_avg[1] / (results.filtersResume.filtro_avg[0] + results.filtersResume.filtro_avg[1])) * 100, value3: results.filtersResume.filtro_avg[0], value4: results.filtersResume.filtro_avg[1]},
        { title: 'Filtro U3', value1: (results.filtersResume.filtro_u3[0] / (results.filtersResume.filtro_u3[0] + results.filtersResume.filtro_u3[1])) * 100, value2: (results.filtersResume.filtro_u3[1] / (results.filtersResume.filtro_u3[0] + results.filtersResume.filtro_u3[1])) * 100, value3: results.filtersResume.filtro_u3[0], value4: results.filtersResume.filtro_u3[1]}
      ];
    });

    const progressGroupExample1 = [
      { title: 'Monday', value1: 34, value2: 78 },
      { title: 'Tuesday', value1: 56, value2: 94 },
      { title: 'Wednesday', value1: 12, value2: 67 },
      { title: 'Thursday', value1: 43, value2: 91 },
      { title: 'Friday', value1: 22, value2: 73 },
      { title: 'Saturday', value1: 53, value2: 82 },
      { title: 'Sunday', value1: 9, value2: 69 },
    ]
    const progressGroupExample2 = [
      { title: 'Male', icon: 'cil-user', value: 53 },
      { title: 'Female', icon: 'cil-user-female', value: 43 },
    ]
    const progressGroupExample3 = [
      {
        title: 'Organic Search',
        icon: 'cib-google',
        percent: 56,
        value: '191,235',
      },
      { title: 'Facebook', icon: 'cib-facebook', percent: 15, value: '51,223' },
      { title: 'Twitter', icon: 'cib-twitter', percent: 11, value: '37,564' },
      { title: 'LinkedIn', icon: 'cib-linkedin', percent: 8, value: '27,319' },
    ]

    return {
      numSegnaliPFC,
      resultsAll,
      progressGroupExample1,
      progressGroupExample2,
      progressGroupExample3,
      FiltersResume
    }

  }
}
</script>
